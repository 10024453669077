import imageMap from './hero-map.jpg';
import iconCoins from './icon-coins.svg';
import iconMap from './icon-map.svg';
import iconPin from './icon-pin.svg';
import imageAppOne from './app-image-1.jpg';
import imageAppTwo from './app-image-2.png';
import imageDashboard from './dashboard.jpg';
import imageAppStore from './app-store-badge.png';
import imagePlayStore from './google-play-badge.png';

export {
  imageMap,
  iconCoins,
  iconMap,
  iconPin,
  imageAppOne,
  imageAppTwo,
  imageDashboard,
  imageAppStore,
  imagePlayStore,
};
