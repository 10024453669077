import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import {
  iconCoins,
  iconMap,
  iconPin,
  imageAppOne,
  imageAppStore,
  imageAppTwo,
  imageDashboard,
  imageMap,
  imagePlayStore,
} from '../images/home';
import logoImage from '../assets/images/logo/logo-green.svg';
import { Link } from 'gatsby';

const StyledHomePage = styled.div`
  color: #003539;

  h1 {
    font-weight: 600;
    font-size: 40px;
    color: #003539;
    letter-spacing: 0;
    line-height: 44px;
  }

  h2 {
    font-size: 36px;
    color: #003539;
    text-align: center;
    line-height: 44px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 16px;
    color: #003539;
    text-align: left;
    line-height: 24px;
  }

  .home-page {
    &__header {
      width: 900px;
      padding: 45px 30px;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      &__links {
        text-align: right;
      }

      &__link {
        color: #64b144;
        font-weight: 600;
      }
    }

    &__section {
      width: 900px;
      padding: 0 30px;
      max-width: 100%;
      margin: 0 auto;
    }

    &__hero {
      display: flex;

      p {
        font-weight: 300;
        font-size: 20px;
        color: #003539;
        letter-spacing: 0;
        line-height: 30px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__image {
        height: 500px;
        width: 70%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 54px;
      }

      @media (max-width: 720px) {
        flex-direction: column-reverse;
        &__image {
          margin: 0 auto;
          height: 0;
          padding-top: 70%;
        }
        &__content {
          max-width: 500px;
          margin: 0 auto;
          text-align: center;
          p {
            text-align: center;
          }
        }
      }
    }

    &__app-store-buttons {
      display: flex;
      margin-top: 24px;
      flex-wrap: wrap;

      @media (max-width: 720px) {
        justify-content: center;
      }
    }

    &__download {
      background-size: contain;
      height: 40px;
      width: 120px;
      background-repeat: no-repeat;
      transition: opacity 0.5s;

      &:hover {
        opacity: 0.85;
      }

      &__ios {
        background-image: url(${imageAppStore});
      }
      &__droid {
        background-image: url(${imagePlayStore});
        margin-left: 8px;
      }
    }

    &__app {
      &__images {
        display: flex;
      }

      &__image {
        flex: 1;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: 36px;
        padding-top: 60%;

        @media (max-width: 720px) {
          &__one {
            display: none;
          }
        }
      }
    }

    &__features {
      display: flex;

      &__feature {
        display: flex;
        flex: 1;
        margin: 0 12px;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }

        &__text {
          font-size: 16px;
          color: #003539;
          text-align: left;
          line-height: 24px;
        }

        &__icon {
          flex-shrink: 0;
          height: 20px;
          width: 14px;
          margin-right: 12px;
          margin-top: 6px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top left;
        }
      }

      @media (max-width: 720px) {
        flex-direction: column;

        &__feature {
          margin: 0 auto;
          max-width: 450px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-right: auto;
          }
        }
      }
    }

    &__app-overview {
      padding-top: 12px;
      padding-bottom: 54px;
    }

    &__dashboard {
      text-align: center;

      h2 {
        max-width: 720px;
      }

      p {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 36px;
        text-align: center;
      }

      &__prompt {
        font-weight: 600;
        font-size: 24px;
        color: #6db54c;
        letter-spacing: 0;
        line-height: 30px;
      }

      &__cta {
        padding: 12px 18px;
        background: #6db54c;
        display: inline-block;
        margin-top: 24px;
        border-radius: 9px;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 16px;
      }

      &__image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 50%;
        margin: 64px 32px;
      }
    }

    &__footer {
      background-color: #003539;
      color: white;

      a {
        color: white;
        text-decoration: underline;
      }

      &__wrapper {
        max-width: 900px;
        margin: 0 auto;
        padding: 45px 30px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        address {
          margin-right: 24px;
          font-style: normal;
        }
      }

      &__links {
        max-width: 900px;
        margin: 0 auto;
        padding: 0 30px 45px;
        display: flex;
        justify-content: center;
        color: #449ea5;
        flex-wrap: wrap;
        a {
          margin-right: 24px;
        }
      }
    }
  }
`;

const StyledNavigationLogo = styled.div.attrs({ image: logoImage })`
  background-image: ${({ image }) => `url(${image})`};
  height: 30px;
  width: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  flex-grow: 1;
`;

const HomePage = () => (
  <StyledHomePage>
    <Helmet>
      <title>Seedz - Discover prizes &amp; offers near you</title>
    </Helmet>
    <div className="home-page__header">
      <StyledNavigationLogo />
      <div className="home-page__header__links">
        <Link to="/map" className="home-page__header__link">
          Dashboard
        </Link>
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <Link to="/register" className="home-page__header__link">
          Register
        </Link>
      </div>
    </div>
    <div className="home-page__section home-page__hero">
      <div className="home-page__hero__content">
        <h1>Discover prizes &amp; offers near you.</h1>
        <p>
          Unlock local experience through food, entertainment, sports, and more!
        </p>
        <div className="home-page__app-store-buttons">
          <a
            className="home-page__download home-page__download__ios"
            href="https://apps.apple.com/us/app/seedz/id1438681617"
            target="_blank"
          />
          <a
            className="home-page__download home-page__download__droid"
            href="https://play.google.com/store/apps/details?id=com.seedz"
            target="_blank"
          />
        </div>
      </div>
      <div
        className="home-page__hero__image"
        style={{ backgroundImage: `url(${imageMap})` }}
      />
    </div>
    <div className="home-page__section home-page__app-overview">
      <h2>Seedz gives you the best in local experience.</h2>
      <div className="home-page__features">
        <div className="home-page__features__feature">
          <div
            className="home-page__features__feature__icon"
            style={{ backgroundImage: `url(${iconMap})` }}
          />
          <p className="home-page__features__feature__text">
            Explore your city and receive exclusive offers, prizes and content
            to geolocations near you.
          </p>
        </div>
        <div className="home-page__features__feature">
          <div
            className="home-page__features__feature__icon"
            style={{ backgroundImage: `url(${iconCoins})` }}
          />
          <p className="home-page__features__feature__text">
            Earn points to get exclusive discounts on events, promotions, and
            more!
          </p>
        </div>
        <div className="home-page__features__feature">
          <div
            className="home-page__features__feature__icon"
            style={{ backgroundImage: `url(${iconPin})` }}
          />
          <p className="home-page__features__feature__text">
            Easily browse offers and deals on the map and follow it’s location
            to collect a Seed. It's that easy!
          </p>
        </div>
      </div>
      <div className="home-page__app__images">
        <div
          className="home-page__app__image home-page__app__image__one"
          style={{ backgroundImage: `url(${imageAppOne})` }}
        />
        <div
          className="home-page__app__image home-page__app__image__two"
          style={{ backgroundImage: `url(${imageAppTwo})` }}
        />
      </div>
    </div>
    <div className="home-page__section home-page__dashboard">
      <h2>Plant Seedz, Grow your Business.</h2>
      <p>
        <strong>Seedz</strong> provides an integrated dashboard for businesses
        to easily create campaigns and add locations on the map.
      </p>
      <div className="home-page__dashboard__prompt">
        Let us help you grow your community.
      </div>
      <Link className="home-page__dashboard__cta" to="/register">
        Get Started
      </Link>
      <div
        className="home-page__dashboard__image"
        style={{ backgroundImage: `url(${imageDashboard})` }}
      />
    </div>
    <div className="home-page__footer">
      <div className="home-page__footer__wrapper">
        <address>
          140 Christina Street North
          <br />
          Sarnia, ON N7T 5T9
        </address>
        <div>
          <a href="mailto:skiernan@seedz.co">skiernan@seedz.co</a>
        </div>
      </div>
      <div className="home-page__footer__links">
        <a href="/terms-and-conditions" target="_blank">
          Terms and Conditions
        </a>
        <a href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        <div>Copyright 2018 &copy; Seedz Corp</div>
      </div>
    </div>
  </StyledHomePage>
);

export default HomePage;
